/* global Swiper */
( () => {

	// Block Name
	const blockName = 'text-slider';
	// Environment
	const method = ( document.querySelector( 'body' ) === null || document.querySelector( 'body' ).classList.contains( 'wp-admin' ) ) ? 'wp-admin' : 'front';
	/**
	 * Initialize Block
	 *
	 * @param  {node} block
	 *
	 * @return {mixed}
	 */
	const initializeBlock = ( block ) => {

		if ( block[ 0 ] !== undefined ) {

			block = block[ 0 ].querySelector( `.b-${ blockName }` );

		}
		if ( block === false || block === null ) {

			return false;

		}

		/**
		 * Get height of the highest element
		 *
		 * @return {number}
		 */
		const getHeight = () => {

			const menuSliderItems = block.querySelectorAll( '.swiper-slide .text-slider-item' );

			let maxHeight = 0;

			menuSliderItems.forEach( ( item ) => {

				const itemHeight = item.offsetHeight;

				if ( itemHeight > maxHeight ) {

					maxHeight = itemHeight;

				}

			} );

			return maxHeight;

		};

		const blockSwiper = block.querySelector( '.text-slider' );

		new Swiper( blockSwiper, {
			slidesPerView: 1,
			spaceBetween: 0,
			pagination: {
				el: block.querySelector( '.swiper-pagination' ),
				clickable: true,
			},
			breakpoints: {
				1024: {
					slidesPerView: 2,
					spaceBetween: 0,
				}
			},
			//autoHeight: true,
			height: getHeight(),
		} );

		// Get all images in slider and set height for images base on the highest element list
		const sliderImages = blockSwiper.querySelectorAll( '.text-slider-image' );
		const swiperHeight = getHeight();
		sliderImages.forEach( ( image ) => {

			image.style.height = `${swiperHeight}px`;

		} );

		return block;

	}
	/**
	 * Get all blocks
	 *
	 * @return {void}
	 */
	const getBlocks = () => {

		const blocks = document.querySelectorAll( `.b-${ blockName }` );
		if ( blocks.length > 0 ) {

			blocks.forEach( ( block ) => {

				if ( block !== false && block !== null ) {

					initializeBlock( block );

				}

			} );

		}

	}
	/**
	 * Run on frontend
	 *
	 * @param  {string} method
	 *
	 * @return {void}
	 */
	if ( method === 'front' ) {

		document.addEventListener( 'DOMContentLoaded', getBlocks );

	}
	/**
	 * Run on backend
	 *
	 * @param  {string} method
	 *
	 * @return {void}
	 */
	if ( method === 'wp-admin' ) {

		window.addEventListener( 'load', () => {

			if ( window.acf ) {

				getBlocks();
				// Add update block action for ACF
				window.acf.addAction( `render_block_preview/type=${ blockName }`, initializeBlock );

			}

		} );

	}

} )();
